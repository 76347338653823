import $device from '../../lib/device';
import navigateTo from '../../packages/jsapi/routing/navigateTo';

export const jumpToUrl = function (url: string, isCurrentWindow?: boolean) {
  if (isCurrentWindow) {
    location.href = url;
  } else {
    if ($device.isAPP) {
      navigateTo({ url }).then(() => {});
    } else {
      window.open(url);
    }
  }
};

export interface IHHOUrlComposerParams {
  search?: string;
  hash?: string;
  path: string;
}

const isDev: boolean = process.env.NODE_ENV === 'development' || process.env.NODE_ENV === 'test';

const Host = {
  online: 'm.hhodata.com',
  pre: 'pre-m.hhodata.com',
  daily: 'daily-m.hhodata.com'
};

// 获取主站的host
export const getMainEnvHost = () => {
  let host = window.location.host;
  if (host.startsWith('daily') || host.startsWith('localhost')) {
    // host = Host.daily;
    host = Host.pre;
  } else if (host.startsWith('pre')) {
    host = Host.pre;
  } else {
    host = Host.online;
  }
  return host;
};

export const hhoUrlComposer = (params: IHHOUrlComposerParams, isOuter = false) => {
  const { search, hash, path } = params;

  let host = window.location.host;

  const devOrigin = `${window.location.protocol}//${host}`;
  let onLineOrigin = `https://${host}`;
  const originUrl: string = isDev ? devOrigin : onLineOrigin;

  const pagePath = path.startsWith('/') ? path : `/${path}`;
  let url = `${originUrl}${pagePath}`;
  if (hash) {
    const pageHash = hash.startsWith('#') ? hash : `#${hash}`;
    url = url + pageHash;
  }
  if (search) {
    const pageSearch = search.startsWith('?') ? search : `?${search}`;
    url = url + pageSearch;
  }
  return url;
};
