import { IMap } from '@/types/base';
import Cookies from 'js-cookie';
import queryString from 'query-string';

const opts = {
  host: 'ap-southeast-1.log.aliyuncs.com',
  project: 'hho-user-growth',
  logstore: 'share_log',
};

// 用时间戳作为每个会话周期的session
const sessionLogId = Date.now();

/**
 * 通用的日志send
 * 阿里云日志服务介绍：https://help.aliyun.com/document_detail/31752.html
 * @param options
 */

export const send = (options: IMap<string | number>) => {
  const image = new Image();
  const { host, project, logstore } = opts;
  const logBaseParams = {
    page: `${window.location.host}${window.location.pathname}`,
    uid: Cookies.get('_gid') || '-',
    dl: window.location.href,
    sid: sessionLogId,
  };
  const logOptions = {
    ...logBaseParams,
    ...options,
  };
  const str = queryString.stringify(logOptions);
  image.src = `//${project}.${host}/logstores/${logstore}/track_ua.gif?APIVersion=0.6.0&${str}`;
};
