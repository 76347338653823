import axios from "axios";
import { loginJwtTokenHandle } from "../helper";
import {userInfoHandle} from "@/services/login";
import getDeviceId from "@/utils/getDeviceId";

function getJwtToken() {
  return loginJwtTokenHandle.get();
}

axios.interceptors.request.use(
  async function (config: any) {
    // Do something before request is sent
    if (process.env.NODE_ENV === "development") {
      // console.log(`[axiosRequest with config]`, config);
    }
    const jwtToken = getJwtToken();
    config.headers.Authorization = `Bearer ${jwtToken}`;
    const userId = userInfoHandle.get()?.hhoUserId;
    const deviceId = await getDeviceId();
    config.headers['HHO-USER-ID'] = userId || '';
    config.headers['device-id'] = deviceId || '';
    return config;
  },
  function (error) {
    if (process.env.NODE_ENV === "development") {
      // console.log(`[axiosRequest with error]`, error);
    }
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function (response) {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    if (process.env.NODE_ENV === "development") {
      // console.log(`[axiosResponse]`, response);
    }

    return response;
  },
  function (error) {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.log(`[axiosResponse] with error`, error);
    return Promise.reject(error);
  }
);
axios.defaults.withCredentials = true;
axios.defaults.timeout = 20000; // 20s timeout
export const hhoAxios = axios;
