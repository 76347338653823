import $device from '@/lib/device';
import getSystemInfo from '@/packages/jsapi/basic/getSystemInfo';
import { pcDeviceIdHandle } from '@/services/login';

/**
 * 获取设备ID
 * @returns
 */
const getDeviceId = async (): Promise<string> => {
    return new Promise((resolve, reject) => {
        const localDeviceId = localStorage.getItem('hho_app_deviceId');
        if (localDeviceId) {
            resolve(localDeviceId);
        } else {
            if ($device.isAPP) {
                getSystemInfo()
                    .then(sysResult => {
                        if (sysResult.deviceId) {
                            resolve(sysResult.deviceId);
                            localStorage.setItem(
                                'hho_app_deviceId',
                                sysResult.deviceId
                            );
                        } else {
                            reject(new Error('DeviceId is null'));
                        }
                    })
                    .catch(() => {
                        reject(new Error('Get DeviceId Error'));
                    });
            } else {
                resolve(pcDeviceIdHandle.get());
            }
        }
    });
};

export default getDeviceId;
