export const fire = <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path
    d="M13.8474 7.78946C13.5236 7.06231 13.0604 6.41817 12.4711 5.87696L11.988 5.4337C11.9166 5.37061 11.8037 5.39883 11.7722 5.48848L11.5563 6.10772C11.4219 6.4962 11.1745 6.89297 10.8242 7.28311C10.801 7.30801 10.7744 7.31465 10.7562 7.31631C10.7379 7.31797 10.7097 7.31465 10.6848 7.29141C10.6615 7.27149 10.6499 7.24161 10.6516 7.21172C10.713 6.21231 10.4142 5.08506 9.76006 3.85821C9.21885 2.83887 8.4668 2.04366 7.52715 1.48916L6.8415 1.08575C6.75186 1.03262 6.6373 1.10235 6.64229 1.20694L6.67881 2.00381C6.70371 2.54834 6.64062 3.02979 6.49121 3.42989C6.30859 3.91963 6.04629 4.37452 5.71094 4.78292C5.4752 5.06846 5.21123 5.32579 4.92236 5.54825C4.22344 6.08448 3.64736 6.7834 3.25723 7.56534C2.86377 8.35557 2.65625 9.24043 2.65625 10.127C2.65625 10.9106 2.81064 11.6692 3.11611 12.3848C3.41162 13.0737 3.8333 13.6946 4.36953 14.2259C4.90742 14.7571 5.53164 15.1755 6.22725 15.466C6.94775 15.7682 7.71143 15.9209 8.5 15.9209C9.28857 15.9209 10.0522 15.7682 10.7728 15.4677C11.4684 15.1772 12.0942 14.7605 12.6305 14.2275C13.1684 13.6963 13.59 13.0754 13.8839 12.3864C14.1894 11.6709 14.3438 10.9122 14.3438 10.1286C14.3438 9.31846 14.1777 8.53155 13.8474 7.78946Z"
    fill="url(#paint0_linear_80_11673)"
  />
  <defs>
    <linearGradient
      id="paint0_linear_80_11673"
      x1="8.5"
      y1="1.0675"
      x2="8.5"
      y2="15.9209"
      gradientUnits="userSpaceOnUse"
    >
      <stop stopColor="#FF9C41" />
      <stop offset="1" stopColor="#FF4141" stopOpacity="0.87" />
    </linearGradient>
  </defs>
</svg>;

export const swiperItems = [
  'https://static.hhocool.com/images/23c19826-3ab4-4c85-96ba-a366457524cd_318x368.png',
  'https://static.hhocool.com/images/49e7c0fe-1912-432f-af57-16e8a9b48579_318x368.png',
  'https://static.hhocool.com/images/e6bb658d-d3a8-42a8-be70-82e0b0da0ff5_318x368.png',
  'https://static.hhocool.com/images/1c076c61-e39a-4629-b83c-7bba1c43466d_318x368.png',
  'https://static.hhocool.com/images/daf81a1e-2fb9-495b-868e-d7ad0d1766de_318x368.png',
  'https://static.hhocool.com/images/da608487-deb6-4f1e-b9af-138ef7dd9dbd_318x368.png'
]


