interface DeviceModel {
  isTablet: boolean;
  isIPhone: boolean;
  isAndroid: boolean;
  isPc: boolean;
  isAPP: boolean;
  isWeChat: boolean;
}

function device(): DeviceModel {
  const ua = window.navigator.userAgent;
  const isAPP = /HHOStore/i.test(ua);
  const isAndroid = /(?:Android)/.test(ua);
  const isFireFox = /(?:Firefox)/.test(ua);
  const isTablet =
    /(?:iPad|PlayBook)/.test(ua) ||
    (isAndroid && !/(?:Mobile)/.test(ua)) ||
    (isFireFox && /(?:Tablet)/.test(ua));

  const isIPhone = /(?:iPhone)/.test(ua) && !isTablet;
  const isPc = !isIPhone && !isAndroid;
  const isWeChat = /MicroMessenger/i.test(ua);
  return {
    isTablet: isTablet,
    isIPhone: isIPhone,
    isAndroid: isAndroid,
    isPc: isPc,
    isAPP: isAPP,
    isWeChat: isWeChat,
  };
}

export default device();
