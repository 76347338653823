import React from "react";
import ReactDOM from "react-dom";

import Index from "@/pages/index";
// import initVConsole from "./helper/initVConsole";
import "@/scss/iconfont.scss";
import "@/scss/normalize.scss";
import "@/i18n/index";

// initVConsole();


ReactDOM.render(
  <React.StrictMode>
    <Index />
  </React.StrictMode>,
  document.getElementById("root")
);
