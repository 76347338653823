/**
 * @desc 最新ga4版本日志打印
 */
// import { callJsBridge } from '@/packages/jsapi/core';
import dsBridge from 'dsbridge';
interface ILoggerParams {
    eventName: string;
    params: {
        [key: string]: any;
    };
}

const ga4Api = (p: ILoggerParams) => {
    const apiName: string = 'monitor.commitGA4';
    console.log('ga4Api', (p));
    return dsBridge.call(apiName, p);
};

export default ga4Api;
