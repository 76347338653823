/**
 * 
 */
import { callJsBridge } from '../core';
export interface IGetSystemInfoParams {
}
export enum AppConnectionEnum {
    daily = 'daily',
    online = 'online',
}
export interface IGetSystemInfoResult {
    brand: string;
    model: string;
    deviceId: string;
    screenWidth: number;
    screenHeight : number;
    windowWidth: number;
    windowHeight: number;
    statusBarHeight: number;
    navigationBarHeight: number;
    language: string;
    version: string;
    system: string;
    platform: string;
    isLowPowerMode: boolean;
    connection: AppConnectionEnum; // 默认用online
    inviteCode?: string; // 邀请码从jsapi的上下文获取
    supportLive: boolean;  // 是否展示直播入口
    liveEntranceState: number;
    liveEntranceImg: string; // 直播图片
}
const apiName: string = 'basics.getSystemInfo';
const getSystemInfo = (p: IGetSystemInfoParams = {}): Promise<IGetSystemInfoResult> => {
    return callJsBridge(apiName, p);
};
export default getSystemInfo; 
