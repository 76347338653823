import ga4Api from '@/packages/jsapi/logger/ga4';
import { getMainEnvHost, jumpToUrl } from '@/services/url';
import { withCurrency } from '@/utils';
import { useEffect } from 'react';
import './productItem.scss';
const ProductItem = (props: any) => {
  const {data} = props;

  useEffect(() => {
    ga4Api({
      eventName: "hho_customize_view",
      params: {
        click: "recommend_product_exposure",
        itemId: data.itemId,
        title: data.title,
        price: data.prePrice
      },
    });
  }, [])

  const toDetail = ()=> {
    ga4Api({
      eventName: "hho_customize_view",
      params: {
        click: "recommend_product_detail",
        itemId: data.itemId,
        title: data.title,
        price: data.prePrice
      },
    });
    const url = `hhostore://hhostoreclient/page/indexBuy?productId=${data.itemId}&itemId=${data.itemId}&hho_nav_bar=1&hho_status_bar=1&isInApp=1&log_channel=banner_tshirt_collect}`
    // const url = `${window.location.protocol}//${getMainEnvHost()}/new_product.html?itemId=${data.itemId}&hho_nav_bar=1`;
    jumpToUrl(url)
  }
  return <div className='product-item-wrapper' style={{marginLeft: '12px'}} onClick={toDetail}>
    <div className="product-main-image">
      <img className='img' src={data.mainImage} alt="" />
    </div>
    {/* <div className="product-main-video">
      <video className='video' src="https://static.hhocool.com/customize/normal%20video.mp4">
      </video>
    </div> */}
    <div className='product-title'>{data.title}</div>
    <div className='product-price'>
      <span className='number'>{withCurrency(data.prePrice)}</span>
      <span className='unit'>円</span>
    </div>
  </div>
}

export default ProductItem