/**
 * see http://docs-aliyun.cn-hangzhou.oss.aliyun-inc.com/pdf/arms-arms-retcode-cn-zh-2018-03-02.pdf
 * @decs __bl.api(api, success, time, code, msg)
 */
export interface IArmsLogSdk {
    api: any;
    error: any;
    sum: any;
    avg: any;
}
// 让它lazy去取，现在是异步加载arms
export const armsLogSenderCore = (): IArmsLogSdk => {
    const sender = (window as any).__bl;
    if (sender) {
        return sender;
    } else {
        return {
            api: console.log,
            error: console.log,
            sum: console.log,
            avg: console.log,
        };
    }
};