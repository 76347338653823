import { send } from "./slsCore";
/**
 * 封装的一层api send 日志信息
 * @param options
 */

export interface IApiInfoModel {
  api?: string; // api名称
  time: number; // 请求耗时
  code: number; // response code
  method?: string; // request method
  err_msg?: string; // 错误信息
  status?: "success" | "fail"; // 成功/失败
}

export const sendApiLogger = (options: IApiInfoModel) => {
  const info = {
    t: "api", // 日志类型
  };
  const message = {
    ...info,
    ...options,
  };
  // console.log("[apiLog]", message);
  send(message);
};
