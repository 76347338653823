/**
 * 
 */
 import { callJsBridge } from '../core';

 export interface IGetAppInstalledResult {
    userId: string;
    nickName: string;
    photo: string;
    token: string;
    email: string;
    verifyEmail: boolean;
 }
 const apiName: string = 'login.getUserInfo';
 const getUserInfo = (): Promise<IGetAppInstalledResult> => {
     return callJsBridge(apiName, {});
 };
 export default getUserInfo; 
 