/**
 * @desc 监控整站的预加载效果
 * @usage
 * ArmsPreloadLatencyInst.addPreloadWebview
 * ArmsPreloadLatencyInst.triggerNavigation
 */
import { arms_apiLog, IApiLogModel } from '../arms/index';
import { IMap } from '@/types/base';
import { Webview_Keys } from '@/packages/jsapi/preload/preloadDef';
export interface IPreloadWebviewItem {
    webviewUrl?: string;
    startTime?: number;
    webviewKey: Webview_Keys;
}
export class ArmsPreloadLatency {
    public preloadWebViewMap: IMap<IPreloadWebviewItem> = {}; // number是一个时间戳
    private findPreloadWebviewByUrl(url: string): IPreloadWebviewItem {
        let key: Webview_Keys = Webview_Keys.Placeholder;
        if (url.match('/products/')) {
            key = Webview_Keys.Detail;
        } else if (url.match('/fresher')) {
            key = Webview_Keys.Fresher;
        } else if (url.match('/checkout')) {
            key = Webview_Keys.Checkout;
        } else if (url.match('/order')) {
            key = Webview_Keys.Order;
        } else if (url.match('/account')) {
            key = Webview_Keys.OrderList;
        } else if (url.match(/register|login/)) {
            key = Webview_Keys.Login;
        }
        if (this.preloadWebViewMap[key]) {
            return this.preloadWebViewMap[key];
        } else {
            return {
                webviewKey: key,
            };
        };
    }
    public deletePreloadWebView(webviewKey: Webview_Keys) {
        delete this.preloadWebViewMap[webviewKey];
    }
    public addPreloadWebview(webviewKey: Webview_Keys, webviewUrl: string) {
        this.preloadWebViewMap[webviewKey] = {
            startTime: Date.now(),
            webviewUrl,
            webviewKey,
        };
    }
    public triggerNavigation(webviewUrl: string) {
        // 命中webview
        const runtimePreloadWebview = this.findPreloadWebviewByUrl(webviewUrl);
        const preloadApiName = this.getWebViewApi(runtimePreloadWebview?.webviewKey);
        let time = 0;
        let success: true|false = false;
        if (runtimePreloadWebview && runtimePreloadWebview.startTime) {
            time = Date.now() - runtimePreloadWebview.startTime;
            success = true;
        }
        const apiLog: IApiLogModel = {
            code: 0,
            api: preloadApiName,
            time,
            success,
            msg: webviewUrl,
        };
        arms_apiLog(apiLog)
    }
    private getWebViewApi(name: Webview_Keys) {
        return `preload_to_navi_latency_${name}`;
    }
}
export const ArmsPreloadLatencyInst = new ArmsPreloadLatency();