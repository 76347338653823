/**
 * 首页：index
 * 详情：goodDetail
 * 新人专区：fresher
 * 下单页面: checkout
 * 订单详情：orderDetail
 * 登录注册：login
 * 订单列表：orderList
 */

export enum Webview_Keys {
    Index = 'index',
    Detail = 'productDetail',
    Fresher = 'fresher',
    Checkout = 'checkout',
    Order = 'orderDetail',
    OrderList = 'orderList',
    Login = 'login',
    Placeholder = 'Placeholder',
    Instruction = 'Instruction',
    Coupon = 'Coupon'
}
