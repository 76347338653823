/**
 * @desc
 */
 import { callJsBridge } from '../core';
 export interface IExitPageParams {
     dontCloseActivity?: boolean;
 }
 export interface IExitPageResult {
 }
const exitPage = (p: IExitPageParams): Promise<IExitPageResult> => {
     const apiName: string = 'routing.exitPage';
     return callJsBridge(apiName, {
          dontCloseActivity: true, // 仅安卓有效。防止栈低页面退出导致闪退
          ...p
     });
};
 
export default exitPage;
