import { isDaily } from '../../lib/env';
import { v4 as uuidv4 } from 'uuid';

/**
 * @desc jwt-token 相关
 */
export const loginJwtTokenHandle = {
  tokenName: 'Jwt-Token',
  set(hhoToken: string) {
    localStorage.setItem(loginJwtTokenHandle.tokenName, hhoToken);
    console.log('[loginJwtTokenHandle] try set', loginJwtTokenHandle.tokenName, hhoToken);
  },
  get(): string {
    console.log('[loginJwtTokenHandle] try get', loginJwtTokenHandle.tokenName);
    return (window as any).localStorage.getItem(loginJwtTokenHandle.tokenName) || '';
  },
  remove() {
    console.log('[loginJwtTokenHandle] try remove', loginJwtTokenHandle.tokenName);
    localStorage.removeItem(loginJwtTokenHandle.tokenName);
  }
};

export interface ILocalUserInfo {
  hhoUserMail: string;
  hhoUserId: string | number;
  accountVerified?: boolean;
}
/**
 * 本地用户信息
 */
export const userInfoHandle = {
  localName: 'hho_local_userInfo',
  set(params: ILocalUserInfo) {
    localStorage.setItem(userInfoHandle.localName, JSON.stringify(params || {}));
    console.log('[userInfoHandle] try set', userInfoHandle.localName, params);
  },
  get(): ILocalUserInfo {
    console.log('[userInfoHandle] try get', userInfoHandle.localName);
    const usrInfoStr = (window as any).localStorage.getItem(userInfoHandle.localName) || '{}';
    return JSON.parse(usrInfoStr);
  },
  remove() {
    console.log('[userInfoHandle] try remove', userInfoHandle.localName);
    localStorage.removeItem(userInfoHandle.localName);
  }
};

/**
 * 本地登录信息保存和删除
 */
export interface ILocalLoginInfo {
  token: string;
  hhoUserMail: string;
  hhoUserId: string | number;
}
export const localLoginInfoHandle = {
  set(params: ILocalLoginInfo) {
    const { token, hhoUserMail, hhoUserId } = params || {};
    loginJwtTokenHandle.set(token);
    userInfoHandle.set({ hhoUserMail, hhoUserId });
  },
  remove() {
    loginJwtTokenHandle.remove();
    userInfoHandle.remove();
  }
};

/**
 * @desc pc版本的uuid 相关
 */
export const pcDeviceIdHandle = {
  tokenName: 'hho_pc_deviceId',
  singletonSet() {
    if (!pcDeviceIdHandle.get()) {
      localStorage.setItem(pcDeviceIdHandle.tokenName, `pc_hho_cool_${uuidv4()}`);
    }
  },
  get(): string {
    return localStorage.getItem(pcDeviceIdHandle.tokenName) || '';
  }
};
pcDeviceIdHandle.singletonSet();

// 根据不同的环境采用不同的baseUrl
const isDev: boolean = process.env.NODE_ENV === 'development';
const onlineUrl: string = 'https://api.hhocool.com/';
const dailyUrl: string = 'https://daily-api.hhocool.com/';

export const getHHORpcEnvBaseUrl = async (): Promise<string> => {
  // if (isDev || isDaily()) {
  //   return dailyUrl;
  // }

  return onlineUrl;
};
