/**
 * jsbridge api 简单的封装了一层，方便调用
 * https://alidocs.dingtalk.com/i/team/apjG58DP09y10mkE/docs/apjG5dx9rBKedmkE?doc-mode=edit# 「N项目JSAPI」
 *
 * 使用方法
 *
 * await callJsBridge('basics.getSystemInfo');
 * await callJsBridge('forwarding.showShareMenu', {});
 */

import dsBridge from 'dsbridge';
import { arms_apiLog } from '@/lib/log/arms';
import $device from '@/lib/device';
import { ArmsPreloadLatencyInst } from '@/lib/log/armsAnalytics/preload';
import { IGetPreloadParams } from '@/packages/jsapi/preload/preloadWebview';
import { INavigateToParams } from '@/packages/jsapi/routing/navigateTo';
import { IDeletePreloadParams } from '@/packages/jsapi/preload/deletePreloadWebView';

const hookCall = (methodName: string, params: any, res: any) => {
    if (methodName === 'preload.preloadWebView') {
        ArmsPreloadLatencyInst.addPreloadWebview((params as IGetPreloadParams).key, params.url);
    }
    if (methodName === 'routing.navigateTo') {
        ArmsPreloadLatencyInst.triggerNavigation((params as INavigateToParams).url);
    }
    if (methodName === 'preload.deletePreloadWebView') {
        ArmsPreloadLatencyInst.deletePreloadWebView((params as IDeletePreloadParams).key);
    }
}
export const callJsBridge = function <P, R>(
    namespace: string,
    params: P
): Promise<R> {
    return new Promise((resolve, reject) => {
        console.log('[jsapi call]', namespace, params);
        const start = Date.now();
        (window as any).dsBridge.call(namespace, params, function (res: any) {
            const costTime = Date.now() - start;
            const apiName: string = `jsapi_${namespace}`;
            console.log('[jsapi response]', costTime, namespace, params, res);
            if (res.success) {
                resolve(res.data);
                hookCall(namespace, params, res);
                arms_apiLog({
                    api: apiName,
                    success: true,
                    time: costTime,
                    code: 200,
                    msg: JSON.stringify(params),
                });
            } else {
                reject(res.message);
                if ($device.isAPP) {
                    arms_apiLog({
                        api: apiName,
                        success: false,
                        time: costTime,
                        code: 500,
                        msg: res.message,
                    });
                }
            }
        });
    });
};

/**
 * 注册事件
 * @param eventName
 */
export const registerEvent = function (eventName: string): Promise<any> {
    return new Promise(resolve => {
        dsBridge.register(eventName, function (v: any) {
            resolve(v);
        });
    });
};

export const registerEventV2 = function(eventName: string, callback: (v: any) => void) {
    dsBridge.register(eventName, function (v: any) {
        callback(v);
    });
}

/**
 * @desc 判断客户端是否支持该接口
 */
 export const hasNativeMethod=function(apiNmae:string,type?:("all"|"syn"|"asyn")){
    return dsBridge.hasNativeMethod(apiNmae,type)
}
