import i18next from '../i18n';

const t = i18next.t;
// 本地存储
export const localStorageNames = {
    hho_local_userInfo: 'hho_local_userInfo',
    hho_local_lineToken: 'hho_local_lineToken',
    hho_local_email: 'hho_local_email',
    hho_local_password: 'hho_local_password',
    hho_local_fill_password: 'hho_local_fill_password',

    // 是否关闭过注册引导弹框
    hho_register_guide_closed: 'hho_register_guide_closed',
};

export const hhoErrorCodeEnum = {
    'errCode_SERVER_ERROR': t('网络异常'),
    'errCode_PARAMS_ERROR': t('参数异常'),
    'errCode_PARAM_EMPTY': t('数据异常，请重试'),
    'errCode_NOT_FOUND_RECORD': t('数据异常，请重试'),
    'errCode_LINE_TOKEN_INVALID': t('数据异常，请重试'),
    'errCode_LINE_ALREADY_BIND': t('数据异常，请重试'),
    'errCode_CODE_INVALID': t('数据异常，请重试'),
    'errCode_EMAIL_ALREADY_USED': t('邮箱已被注册'),
    'errCode_USER_ALREADY_EXIST': t('邮箱已被注册'),
    'errCode_TOKEN_INVALID': t('请重新登录'),
    'errCode_JWT_TOKEN_EXPIRED': t('请重新登录'),
    'errCode_INVITE_ERROR': t('数据异常，请重试'),
    'errCode_USER_MAIL_NOT_EXIST': t('无此账号，请重新输入'),
    'errCode_USER_NOT_EXIST': t('无此账号，请重新输入'),
    'errCode_PASSWORD_ERROR': t('密码错误，请重新输入'),
    'errCode_USER_NOT_BIND': t('用户未绑定邮箱'),
    'errCode_INVALID_JWT_SECRET': t('请重新登录'),
    'errCode_CREATE_SHOPIFY_CUSTOMER_ERROR': t('注册失败，请重试'),
    'errCode_UNKNOW_ERROR': t('系统异常，请重试'),
    'errCode_LOGIN_FAIL': t('系统异常，请重试'),
    'errCode_REGISTER_FAIL': t('系统异常，请重试'),
    'errCode_VERIFY_TOKEN_FAIL': t('请重新登录'),
    'errCode_USER_STATUS_UN_NORMAL': t('账号异常，请重试'),
    'errCode_USER_STATUS_NOT_NORMAL': t('账号异常，请重试'),
    'errCode_REGION_LIMITED': t('当前仅在日本地区开放，敬请期待'),
    'errCode_USER_DELETING': t('此邮箱账户注销申请在处理中，无法继续使用，如有问题，可联系客服'),
    'errCode_HAS_UNFINISHED_ORDER': t('你的账户存在处理中的订单，暂时无法注销账户'),
    'errCode_INVITE_CODE_ALREADY_USE': t('内测期间，一个邀请码仅限注册一个账号，如有问题请联系客服'),
    'errCode_OPERATION_LIMITED': t('操作已达到上限，今日不可再次发送')
};
