/**
 * @desc
 */
import { callJsBridge } from '../core';
export interface INavigateToParams {
    url: string;
    clearTop?: boolean;
}
export interface IGetSystemInfoResult {}
const navigateTo = (p: INavigateToParams): Promise<IGetSystemInfoResult> => {
    const apiName: string = 'routing.navigateTo';
    return callJsBridge(apiName, p);
};

export default navigateTo;
