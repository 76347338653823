import qs, { ParsedQuery } from 'query-string';
import { localStorageNames } from '@/constant/login';
import { v4 as uuidv4 } from 'uuid';
import getUserInfo from '@/packages/jsapi/login/getUserInfo';
/**
 * @desc jwt-token 相关
 */

/**
 * 用户信息
 */
export interface IUserInfo {
    displayName: string;
    hhoUserMail: string;
    hhoUserId: string | number;
    nickName: string;
    avatar: string;
    accountVerified: boolean;
}

export const loginJwtTokenHandle = {
    tokenName: 'Jwt-Token',
    set(hhoToken: string) {
        localStorage.setItem(loginJwtTokenHandle.tokenName, hhoToken);
        console.log(
            '[loginJwtTokenHandle] try set',
            loginJwtTokenHandle.tokenName,
            hhoToken
        );
    },
    get(): string {
        console.log(
            '[loginJwtTokenHandle] try get',
            loginJwtTokenHandle.tokenName
        );
        return (
            (window as any).localStorage.getItem(
                loginJwtTokenHandle.tokenName
            ) || ''
        );
    },
    remove() {
        console.log(
            '[loginJwtTokenHandle] try remove',
            loginJwtTokenHandle.tokenName
        );
        localStorage.removeItem(loginJwtTokenHandle.tokenName);
    },
};

/**
 * @desc pc版本的uuid 相关
 */
export const pcDeviceIdHandle = {
    tokenName: 'hho_pc_deviceId',
    singletonSet() {
        if (!pcDeviceIdHandle.get()) {
            localStorage.setItem(
                pcDeviceIdHandle.tokenName,
                `pc_hho_cool_${uuidv4()}`
            );
        }
    },
    get(): string {
        return localStorage.getItem(pcDeviceIdHandle.tokenName) || '';
    },
};
pcDeviceIdHandle.singletonSet();

/**
 * @desc 登录/注册模块的querystring
 */
export const parseStrToQuerystring = <T extends ParsedQuery<string>>(
    str: string
): T => {
    const searchReg = /\?/;
    const strPars = str.split(searchReg);
    if (strPars[1]) {
        const normalizedQs = strPars[1].split('#')[0];
        const res = qs.parse(normalizedQs) as any as T;
        return res;
    } else {
        return {} as T;
    }
};
export const getQueryFromSearchOrHash = <T extends any>(): T => {
    return parseStrToQuerystring<any>(location.href) as T;
};
// 获取本地的密码和邮箱
export const tryGetLocalPwdAndEmail = () => {
    const hho_local_email =
        localStorage.getItem(localStorageNames.hho_local_email) || '';
    const hho_local_password =
        localStorage.getItem(localStorageNames.hho_local_password) || '';
    return {
        email: hho_local_email || '',
        md5Pwd: hho_local_password || '',
    };
};

// 设置本地的密码和邮箱
interface ILoginParams {
    hho_local_email: string;
    hho_local_password: string;
    hho_local_fill_password: string;
}
export const trySetLocalPwdAndEmail = (params: ILoginParams) => {
    localStorage.setItem(
        localStorageNames.hho_local_email,
        params.hho_local_email
    );
    localStorage.setItem(
        localStorageNames.hho_local_password,
        params.hho_local_password
    );
    localStorage.setItem(
        localStorageNames.hho_local_fill_password,
        params.hho_local_fill_password
    );
};

// 清空本地存储
export const clearUpLoginLocalStorages = () => {
    // 清空本地记录的邮箱密码
    localStorage.removeItem(localStorageNames.hho_local_email);
    localStorage.removeItem(localStorageNames.hho_local_password);
    localStorage.removeItem(localStorageNames.hho_local_fill_password);
    // 清空lineToken
    localStorage.removeItem(localStorageNames.hho_local_lineToken);
    // 清空jwtToken
    loginJwtTokenHandle.remove();
    userInfoHandle.remove();
};

export interface ICustomerInfoModel {
    first_name?: string;
    last_name?: string;
    email?: string;
    id?: string;
}
export const getDisplayName = (
    customer_info: ICustomerInfoModel = {}
): string => {
    if (!customer_info.email) {
        return '';
    } else {
        return (
            `${customer_info.first_name}${customer_info.last_name}` ||
            customer_info.email.split('@')?.[0]
        );
    }
};

export interface ILocalUserInfo {
    displayName: string;
    hhoUserMail: string;
    hhoUserId: string | number;
    accountVerified: boolean;
    nickName: string;
    avatar: string;
}
/**
 * 本地用户信息
 */
export const userInfoHandle = {
    localName: 'hho_local_userInfo',
    set(params: ILocalUserInfo) {
        localStorage.setItem(
            userInfoHandle.localName,
            JSON.stringify(params || {})
        );
        console.log(
            '[userInfoHandle] try set',
            userInfoHandle.localName,
            params
        );
    },
    get(): ILocalUserInfo {
        console.log('[userInfoHandle] try get', userInfoHandle.localName);
        const usrInfoStr =
            (window as any).localStorage.getItem(userInfoHandle.localName) ||
            '{}';
        return JSON.parse(usrInfoStr);
    },
    remove() {
        console.log('[userInfoHandle] try remove', userInfoHandle.localName);
        localStorage.removeItem(userInfoHandle.localName);
    },
};

/**
 * 本地登录信息保存和删除
 */
export interface ILocalLoginInfo {
    displayName: string;
    token: string;
    accountVerified: boolean;
    hhoUserMail: string;
    hhoUserId: string | number;
    nickName: string;
    avatar: string;
}
export const localLoginInfoHandle = {
    set(params: ILocalLoginInfo) {
        const {
            token,
            hhoUserMail,
            hhoUserId,
            displayName,
            accountVerified,
            nickName,
            avatar,
        } = params || {};
        loginJwtTokenHandle.set(token);
        userInfoHandle.set({
            hhoUserMail,
            hhoUserId,
            displayName,
            accountVerified,
            nickName,
            avatar,
        });
    },
    remove() {
        loginJwtTokenHandle.remove();
        userInfoHandle.remove();
    },
};

export const userIsLogin = ():boolean => {
    if(!loginJwtTokenHandle.get()) {
        getUserInfo().then((res) => {
          loginJwtTokenHandle.set(res.token);
          const displayName = res.email?.split('@')?.[0];
          let userObj = {
                displayName,
                nickName: res.nickName,
                avatar: res.photo,
                hhoUserMail: res.email,
                hhoUserId: res.userId,
                accountVerified: res.verifyEmail,
          }
          userInfoHandle.set(userObj);
          return res.token;
        });
   }
   return !!loginJwtTokenHandle.get();
};

 // 获取用户信息
 export const getUserInfoHandle = () => {
    if(!userInfoHandle.get()) {
        getUserInfo().then((res) => {
            loginJwtTokenHandle.set(res.token);
            const displayName = res.email?.split('@')?.[0];
            let userObj = {
                displayName,
                nickName: res.nickName,
                avatar: res.photo,
                hhoUserMail: res.email,
                hhoUserId: res.userId,
                accountVerified: res.verifyEmail,
            }
            userInfoHandle.set(userObj)
            return userObj;
        });
    } else {
        const { hhoUserId, hhoUserMail, accountVerified, nickName, avatar } = userInfoHandle.get();
        const displayName = hhoUserMail?.split('@')?.[0];
        return { displayName, hhoUserMail, hhoUserId, accountVerified, nickName, avatar};
    }
};
