import { getUserInfoHandle, IUserInfo } from '@/services/login';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import queryString from 'query-string';
import $getSystemInfo from '../../packages/jsapi/basic/getSystemInfo';
import $showShareMenu from '../../packages/jsapi/forwarding/showShareMenu';
import device from '../device';

const fpPromise = FingerprintJS.load();

export enum APP {
    Twitter = 'twitter',
    Line = 'line',
    Facebook = 'facebook',
}

interface OtherModel {
    hashtags?: string[];
    related?: string[];
    via?: string;
    quote?: string;
}

export interface ShareOptions {
    app?: APP[];
    url: string;
    title?: string;
    text?: string;
    imageUrl?: string;
    other?: OtherModel;
}

async function getUrl(options: ShareOptions): Promise<string> {
    const fp = await fpPromise;
    const result = await fp.get();
    // This is the visitor identifier:
    const visitorId = result.visitorId;

    const app = options.app?.[0] || APP.Twitter; // pc端应该只传一个app即可
    const obj = queryString.parseUrl(options.url, {
        parseFragmentIdentifier: true,
    });
    const { url, query, fragmentIdentifier } = obj;
    const params = {
        ...query,
        share: 1,
        t: Date.now(),
        uid: 111, // todo 读取userId
        _did: visitorId,
    };

    options.url = queryString.stringifyUrl({
        url,
        query: params,
        fragmentIdentifier,
    });

    switch (app) {
        case APP.Twitter:
            return getTwitterUrl(options);
        case APP.Facebook:
            return getFacebookLink(options);
        case APP.Line:
            return getLineLink(options);
        default:
            return '';
    }
}

/**
 * twitter 分享
 * @param url
 * @param title
 * @param other, 可以带hashtags, related @xxyy
 */

function getTwitterUrl({ url, text, other = {} }: ShareOptions): string {
    const { hashtags, via, related } = other;
    const params = queryString.stringify({
        url,
        text,
        via,
        hashtags: hashtags && hashtags.join(','),
        related: related && related.join(','),
    });

    return `https://twitter.com/share?${params}`;
}

/**
 * facebook 分享
 * @param url
 * @param title
 */

function getFacebookLink({ url, title }: ShareOptions): string {
    const params = queryString.stringify({
        u: url,
        quote: title,
    });

    return `https://www.facebook.com/sharer/sharer.php?${params}`;
}

/**
 * line 分享
 * @param url
 * @param title
 */
function getLineLink({ url, title }: ShareOptions) {
    const params = queryString.stringify({
        url,
        text: title,
    });
    return `https://social-plugins.line.me/lineit/share?${params}`;
}

async function getAppShareMenuData(options: ShareOptions) {
    let url = options.url;
    const systemInfo = await $getSystemInfo();
    let userInfo: any = getUserInfoHandle();
    const params = queryString.stringify({
        share: 1,
        t: Date.now(),
        uid: userInfo.hhoUserId, // todo 读取userId
        _did: systemInfo.deviceId,
    });
    url = url.includes('?') ? `${url}&${params}` : `${url}?${params}`;
    const { title, text, imageUrl } = options;
    return { title, text, imageUrl, link: url };
}

export async function share(options: ShareOptions) {
    const { isAPP } = device;
    if (isAPP) {
        const data = await getAppShareMenuData(options);
        return $showShareMenu(data);
    } else {
        const url = await getUrl(options);
        window.open(url);
    }
}
