import $device from '@/lib/device';
import { useEffect, useState } from 'react';
import getSystemInfo from '@/packages/jsapi/basic/getSystemInfo';
import { share } from '@/lib/share';

import './index.scss';
import exitPage from '@/packages/jsapi/routing/exitPage';
import { getMainEnvHost, jumpToUrl } from '@/services/url';

const HeaderBar = (props: any) => {
  const [statusTop, setStatusTop] = useState(30);

  const style = {
    paddingTop: statusTop,
    backgroundImage: props.bgImg
  };

  useEffect(() => {
    getSystemInfo().then((res) => {
      setStatusTop(res.statusBarHeight);
      // setWidth(res.screenWidth);
    });
  }, []);
  const handleShare = () => {
    share({
      text: 'オリジナルグッズショップ',
      url: props.shareLink
    }).then((res) => {
      console.log(res);
    });
  };
  const toCart = () => {
    if ($device.isAPP) {
      const url = `https://${getMainEnvHost()}/cart.html`;
      jumpToUrl(url);
    } else {
      location.href = '/cart';
    }
  };
  return (
    <div className='header-bar' style={style}>
      <div
        className='header-back-btn'
        onClick={async () => {
          if ($device.isAPP) {
            await exitPage({});
          } else {
            history.back();
          }
        }}
      >
        {/* <span className="iconfont icon-icon_left_fill" /> */}
        <svg
          width='32'
          height='32'
          viewBox='0 0 32 32'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
        >
          <rect width='32' height='32' rx='16' fill='black' fillOpacity='0.48' />
          <path
            fillRule='evenodd'
            clipRule='evenodd'
            d='M19.2415 8.27307C18.886 7.89657 18.2927 7.87949 17.9162 8.23492L10.737 15.0123C10.1711 15.5466 10.1709 16.4468 10.7366 16.9813L17.9159 23.7647C18.2922 24.1203 18.8856 24.1035 19.2412 23.7271C19.5968 23.3508 19.58 22.7574 19.2036 22.4019L12.4251 15.9972L19.2033 9.59834C19.5798 9.24291 19.5969 8.64957 19.2415 8.27307Z'
            fill='white'
            fillOpacity='0.8'
          />
        </svg>
      </div>
      <div className='header-title' style={{ color: props.color }}>
        {props.title}
      </div>
      {props.showCart ? null : (
        <div className='header-more-btn' onClick={toCart}>
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='32' height='32' rx='16' fill='black' fillOpacity='0.48' />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M8.37218 7.71395C8.05315 7.55444 7.66522 7.68375 7.5057 8.00278C7.34619 8.32181 7.4755 8.70974 7.79453 8.86926L7.8938 8.91889C8.42939 9.18669 8.80268 9.69666 8.89607 10.2881L9.12145 11.7156C9.12248 11.7229 9.12362 11.7301 9.12488 11.7373L10.3483 19.4857C10.5258 20.6097 11.4946 21.4375 12.6325 21.4375H20.8897C21.9883 21.4375 22.9352 20.6647 23.1553 19.5884L24.3485 13.755C24.6418 12.3213 23.5464 10.9791 22.0829 10.9791H10.3128L10.1719 10.0867C10.0135 9.08317 9.38015 8.21794 8.47145 7.76359L8.37218 7.71395ZM11.6241 19.2842L10.5168 12.2708H22.0829C22.7289 12.2708 23.2125 12.8633 23.0831 13.4962L21.8899 19.3296C21.7927 19.8047 21.3747 20.1459 20.8897 20.1459H12.6325C12.1302 20.1459 11.7025 19.7804 11.6241 19.2842ZM13.5 23.4999C13.5 22.8096 12.9404 22.2499 12.25 22.2499C11.5597 22.2499 11 22.8096 11 23.4999C11 24.1903 11.5597 24.7499 12.25 24.7499C12.9404 24.7499 13.5 24.1903 13.5 23.4999ZM21 24.7499C21.6904 24.7499 22.25 24.1903 22.25 23.4999C22.25 22.8096 21.6904 22.2499 21 22.2499C20.3097 22.2499 19.75 22.8096 19.75 23.4999C19.75 24.1903 20.3097 24.7499 21 24.7499Z'
              fill='white'
              fillOpacity='0.8'
            />
          </svg>
        </div>
      )}

      {props.showShare ? (
        <div className='header-more-btn' onClick={handleShare}>
          <svg
            width='32'
            height='32'
            viewBox='0 0 32 32'
            fill='none'
            xmlns='http://www.w3.org/2000/svg'
          >
            <rect width='32' height='32' rx='16' fill='black' fillOpacity='0.48' />
            <path
              fillRule='evenodd'
              clipRule='evenodd'
              d='M15.149 9.70701C15.149 8.43584 16.6714 7.78336 17.5919 8.66003L24.2653 15.0156C24.8635 15.5854 24.8635 16.5398 24.2653 17.1095L17.5919 23.4651C16.6714 24.3418 15.149 23.6893 15.149 22.4181V19.603C12.8003 19.7588 10.6907 20.786 9.13905 22.3656C8.85633 22.6534 8.4595 22.7409 8.11424 22.6494C7.75903 22.5552 7.44053 22.2627 7.37756 21.8225C7.32094 21.4267 7.29169 21.0223 7.29169 20.6115C7.29169 16.1371 10.7562 12.4718 15.149 12.1519V9.70701ZM16.7299 9.5652C16.6052 9.44646 16.399 9.53484 16.399 9.70701V12.7542C16.399 13.0994 16.1192 13.3792 15.774 13.3792C11.7797 13.3792 8.54169 16.6172 8.54169 20.6115C8.54169 20.8032 8.54913 20.993 8.56373 21.1807C10.4495 19.4147 12.9858 18.3324 15.774 18.3324C16.1192 18.3324 16.399 18.6122 16.399 18.9574V22.4181C16.399 22.5903 16.6052 22.6787 16.7299 22.5599L23.4032 16.2044C23.4842 16.1272 23.4842 15.9979 23.4032 15.9208L16.7299 9.5652Z'
              fill='white'
              fillOpacity='0.8'
            />
          </svg>
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default HeaderBar;
