import { hhoAxios } from "./requestAxios";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { getHHORpcEnvBaseUrl } from "../helper";
import { sendApiLogger, IApiInfoModel } from "../../lib/log/sls/slsApiLog";

// 日志上传到sls
export interface IHHORpcRequestModel<H, Req> {
  method: "get" | "post" | "put" | "delete";
  headers: H;
  payload: Req;
  baseUrl?: string;
  url: string;
}

// export type IHHORpcFunc<H, Req, Res> = <H, Req, Res>(
//   req: IHHORpcRequestModel<H, Req>
// ) => Promise<Res>;
export const hhoRpc = async <H, Req, Res>(
  req: IHHORpcRequestModel<H, Req>
): Promise<Res> => {
  const { baseUrl, url, method, headers, payload } = req;
  const baseHeader = {
    // 'Access-Control-Allow-Origin': '*',
    // 'Access-Control-Allow-Credentials': true,
  };
  const axiosArgs: AxiosRequestConfig = {
    method: method || "get",
    headers: { ...(headers || {}), ...baseHeader },
    url,
  };
  let truthyPromise: Promise<void>;
  if (baseUrl) {
    axiosArgs.baseURL = baseUrl;
    truthyPromise = Promise.resolve();
  } else {
    truthyPromise = getHHORpcEnvBaseUrl()
      // 测试代码
      .then((res) => {
        axiosArgs.baseURL = res;
      })
      .catch((error) => {
        console.error(`getHHORpcEnvBaseUrl got error ${error}`);
      });
  }
  await truthyPromise;
  // axiosArgs.withCredentials = true;
  if (method.toLowerCase() === "get") {
    axiosArgs.params = payload;
  } else {
    axiosArgs.data = payload;
  }
  const start = Date.now();
  return hhoAxios(axiosArgs)
    .then((res: AxiosResponse<Res>) => {
      const end = Date.now();
      const apiLog: IApiInfoModel = {
        code: res.status,
        api: `${axiosArgs.baseURL}${axiosArgs.url?.substring(1)}`,
        time: end - start,
        method: axiosArgs.method,
        status: "success",
      };
      sendApiLogger(apiLog);
      if (res.status === 200) {
        const webResponse: Res = res.data;
        return webResponse;
      } else {
        return Promise.reject(res);
      }
    })
    .catch((error) => {
      const end = Date.now();
      const apiLog: IApiInfoModel = {
        code: error.response?.status,
        api: `${axiosArgs.baseURL}${axiosArgs.url?.substring(1)}`,
        time: end - start,
        method: axiosArgs.method,
        err_msg:
          error.response?.message ||
          error.response?.errMessage ||
          `${error && error.toString()}` ||
          "api错误",
        status: "fail",
      };
      sendApiLogger(apiLog);
      return Promise.reject(error);
    });
};
