import Masonry from "react-masonry-component";
import { Swiper } from "antd-mobile";
import ProductItem from "./components/productItem";
import HeaderBar from "@/components/headerbar";
import { InfiniteScroll } from "antd-mobile";
import { fire, swiperItems } from "./constant";
import "./index.scss";
import { useEffect, useRef, useState } from "react";
import { getRecommend } from "@/services/rpc/customize";
import { jumpToUrl } from "@/services/url";
import ga4Api from "@/packages/jsapi/logger/ga4";
const masonryOptions = {
  transitionDuration: 200,
};

let page = 0;

const Index = () => {
  const ml = (window.innerWidth * 0.1) / 3;
  const [hasMore, setHasMore] = useState<boolean>(true);
  const [list, setList] = useState<any>([]);
  const [swiperIndex, setSwiperIndex] = useState<number>(0);
  const swiperRef = useRef<any>(null);
  const hotArea = [1, 2, 3, 4, 5, 6];
  const childElements = list.map(function (data: any, index: number) {
    return <ProductItem key={index} isVideo={index === 4} ml={ml} data={data} />;
  });

  useEffect(() => {
    ga4Api({
      eventName: "hho_customize_index",
      params: {
        link: window.location.href,
      },
    });
  }, []);

  async function loadData() {
    ga4Api({
      eventName: "hho_customize_index",
      params: {
        view: "index_recommend_loadMore",
      },
    });
    const res: any = await getRecommend({ page, size: 20 });
    setList(list.concat(res?.infoPackage?.dataList || []));
    setHasMore(res?.infoPackage?.hasMore || false);
    page++;
  }
  return (
    <div className="index-container">
      <HeaderBar
        bgImg={"linear-gradient(90.34deg, #D1FFE8 0%, #FFE5D1 100%)"}
        title={"オリジナルグッズショップ"}
        color={"#000"}
        showShare={true}
        shareLink="https://hho.page.link/CR9Q"
      />
      <div className="customize-banner">
        <div
          className="to-custom"
          onClick={() => {
            jumpToUrl(`${location.origin}/detail.html?hho_nav_bar=1`);
          }}
        ></div>
        <img
          src="https://static.hhocool.com/images/e1923131-b452-46cc-9f99-13956afbd0fe_750x544.png"
          className="banner-img"
          alt=""
        />
        <div className="swiper-wrapper">
          <Swiper
            indicator={(total, current) => <div></div>}
            loop={true}
            autoplay={true}
            onIndexChange={(index) => {
              setSwiperIndex(index);
            }}
            ref={swiperRef}
          >
            {swiperItems.map((item, index) => {
              return (
                <Swiper.Item key={index}>
                  <img className="swiper-img" src={item} alt="" />
                </Swiper.Item>
              );
            })}
          </Swiper>
        </div>
        <div className="swiper-slide">
          {
            hotArea.map((item, index) => {
              return <div className="slide-item" key={index} onClick={() => {
                swiperRef?.current.swipeTo(index);
              }}></div>
            })
          }
        </div>
        <span className="swiper-indicator" style={{ left: `${swiperIndex * 15.3 + 10.2}vw` }}></span>
      </div>
      <div className="recommend">
        <div className="recommend-title">
          {fire}
          <span className="recommend-title-text">あなたにおすすめ</span>
        </div>
        <Masonry
          className={"my-gallery-class"}
          elementType={"div"}
          options={masonryOptions}
          disableImagesLoaded={false}
          updateOnEachImageLoad={false}
        >
          {childElements}
        </Masonry>
        <InfiniteScroll loadMore={loadData} hasMore={hasMore}>
          <span></span>
        </InfiniteScroll>
      </div>
    </div>
  );
};

export default Index;
