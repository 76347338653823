/**
 * see http://docs-aliyun.cn-hangzhou.oss.aliyun-inc.com/pdf/arms-arms-retcode-cn-zh-2018-03-02.pdf
 * @doc https://help.aliyun.com/document_detail/58657.html
 */
import { armsLogSenderCore } from './core';
export interface IApiLogModel {
    api: string;
    success: boolean;
    time: number;
    code: number;
    msg: string;
}
export const arms_apiLog = (param: IApiLogModel) => {
    try {
        armsLogSenderCore().api(param.api, param.success, param.time, param.code, param.msg);
    } catch(e) {
        console.error('arms api error');
    }
};
// 索引的key值
type TOnceLogKey = (param: IApiLogModel) => string;
const apiOnceLogCacheSet = new Set<string>();
export const arms_apiOnceLog = (param: IApiLogModel, fn: TOnceLogKey) => {
    const indexKey: string = fn(param);
    if (!apiOnceLogCacheSet.has(indexKey)) {
        try {
            armsLogSenderCore().api(param.api, param.success, param.time, param.code, param.msg);
        } catch(e) {
            console.error('arms api error');
        }
    } else {
        console.log('[armsLog]arms_apiOnceLog duplicateLog')
    }
    apiOnceLogCacheSet.add(indexKey);
};

export interface IErrorLogModel {
    error: Error;
    pos?: {
        filename: string;
        lineno: number;
        colno: number;
    }
}
export const arms_errorLog = (param: IErrorLogModel) => {
    try {
        armsLogSenderCore().error(param.error, param.pos);
    } catch(e) {
        console.error('arms arms_errorLog error');
    }
};

export interface ISumLogModel {
    key: string;
    value?: number;
}
export const arms_sumLog = (param: ISumLogModel) => {
    try {
        armsLogSenderCore().sum(param.key, param.value);
    } catch(e) {
        console.error('arms arms_sumLog error');
    }
};


export interface IAvgLogModel {
    key: string;
    value?: number;
}
export const arms_avgLog = (param: IAvgLogModel) => {
    try {
        armsLogSenderCore().avg(param.key, param.value);
    } catch(e) {
        console.error('arms arms_avgLog error');
    }
};
