/**
 * @desc
 */
import { callJsBridge } from '../core';
export interface IShowShareMenuParams {
    title?: string;
    text?: string; // 分享的文本内容
    link: string; //    分享的链接地址
    imageUrl?: string; //    分享的图片地址
}
export interface IShowShareMenuResult {}
const showShareMenu = (
    p: IShowShareMenuParams
): Promise<IShowShareMenuResult> => {
    const apiName: string = 'forwarding.showShareMenu';
    return callJsBridge(apiName, p);
};

export default showShareMenu;
