export default {
  jp: {
    translation: {
      customize_hint_text: 'ご名前や一言を入力してください',
      add_cart: '買い物かごに追加',
      add_cart_success: '買い物かごに追加済み',
      buy_now: '今すぐ買う',
      generating: '提案作成中',
      img_upload_failed: 'アップロードに失敗しました',
      format_adjust: '',
      design_ing: 'デザイン', // 正在为你设计中
      complete: '確認' // 完成
    }
  }
};
